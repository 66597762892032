import React, { useMemo } from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"
import { Link } from "gatsby"

import handleReferenceLink from "../../../../utils/functions/handleReferenceLink"

const NavigationMenuWrapper = styled.div`
  overflow-x: auto;
  border-top: 1px solid #e5e3e4;
  border-bottom: 1px solid #e5e3e4;
  margin-bottom: 20px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    border-top: 0;
    border-bottom: 0;
  }
`

const NavigationMenuItem = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    &::after {
      content: "";
      display: block;
      width: 1px;
      height: 14px;
      background-color: ${(props) => props.theme.colors.primary};
      margin-right: 36px;
      margin-left: 20px;
    }
  }
`

const NavigationMenu = ({ input }) => {
  const items = input.items

  const toLinks = useMemo(() => {
    return items.map((item) => {
      return {
        label: item.name,
        toLink: handleReferenceLink({
          reference: item.reference,
          locale: "en-US",
        }),
      }
    })
  }, [items])

  return (
    <Flex sx={{ flexDirection: "column", px: "16px" }}>
      <NavigationMenuWrapper>
        <Flex
          sx={{
            py: "16px",
            width: "100%",
          }}
        >
          {toLinks.map((item) => {
            return (
              <NavigationMenuItem key={item.name}>
                <Link
                  style={{
                    fontSize: "14px",
                    marginRight: "16px",
                    whiteSpace: "nowrap",
                    color: "#454545",
                    textDecoration: "none",
                    borderBottom: "1px solid transparent",
                  }}
                  activeStyle={{
                    borderBottomColor: "#454545",
                  }}
                  to={item.toLink}
                >
                  {item.label}
                </Link>
              </NavigationMenuItem>
            )
          })}
        </Flex>
      </NavigationMenuWrapper>
    </Flex>
  )
}

export default NavigationMenu
